@font-face {
	font-family: 'icomoon';
	src:url('/fonts/custom-set.eot');
	src:url('/fonts/custom-set.eot?#iefix') format('embedded-opentype'),
		url('/fonts/custom-set.woff') format('woff'),
		url('/fonts/custom-set.ttf') format('truetype'),
		url('/fonts/custom-set.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'icomoon';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-custom-"] {
*/
.icon-custom-refresh, .icon-custom-up, .icon-custom-ui, .icon-custom-thumb, .icon-custom-settings, .icon-custom-right, .icon-custom-previous, .icon-custom-portlets, .icon-custom-next, .icon-custom-newpage, .icon-custom-new, .icon-custom-message, .icon-custom-map, .icon-custom-left, .icon-custom-home, .icon-custom-gallery, .icon-custom-form, .icon-custom-feedbacks, .icon-custom-extra, .icon-custom-downloads, .icon-custom-down, .icon-custom-cross, .icon-custom-chart, .icon-custom-alert {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
.icon-custom-refresh:before {
	content: "\e000";
}
.icon-custom-up:before {
	content: "\e001";
}
.icon-custom-ui:before {
	content: "\e002";
}
.icon-custom-thumb:before {
	content: "\e003";
}
.icon-custom-settings:before {
	content: "\e004";
}
.icon-custom-right:before {
	content: "\e005";
}
.icon-custom-previous:before {
	content: "\e006";
}
.icon-custom-portlets:before {
	content: "\e007";
}
.icon-custom-next:before {
	content: "\e008";
}
.icon-custom-newpage:before {
	content: "\e009";
}
.icon-custom-new:before {
	content: "\e00a";
}
.icon-custom-message:before {
	content: "\e00b";
}
.icon-custom-map:before {
	content: "\e00c";
}
.icon-custom-left:before {
	content: "\e00d";
}
.icon-custom-home:before {
	content: "\e00e";
}
.icon-custom-gallery:before {
	content: "\e00f";
}
.icon-custom-form:before {
	content: "\e010";
}
.icon-custom-feedbacks:before {
	content: "\e011";
}
.icon-custom-extra:before {
	content: "\e012";
}
.icon-custom-downloads:before {
	content: "\e013";
}
.icon-custom-down:before {
	content: "\e014";
}
.icon-custom-cross:before {
	content: "\e015";
}
.icon-custom-chart:before {
	content: "\e016";
}
.icon-custom-alert:before {
	content: "\e017";
}

